import React from 'react';

import UFOLoadHold from '@atlaskit/react-ufo/load-hold';

export function BreadcrumbsPlaceholder() {
	return (
		<UFOLoadHold name="BreadcrumbsPlaceholder">
			<div data-testid="breadcrumbs-placeholder" data-vc="breadcrumbs-placeholder" />
		</UFOLoadHold>
	);
}
