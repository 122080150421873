import {
	CONTEXT_PATH,
	VIEW_BLOG,
	VIEW_PAGE,
	WHITEBOARD_BOARD,
	DATABASE_VIEW,
	EDIT_PAGE_V2,
	EDIT_BLOG_V2,
	EMBED_VIEW,
	FOLDER_VIEW,
} from '@confluence/named-routes';

type GetURLForContentTypeProps = {
	contentType: string | null | undefined;
	contentSubType?: string | null;
	contentId: string | null;
	spaceKey: string;
	redirectToEditor?: boolean;
	useLegacyActionUrl?: boolean;
};

const getLegacyActionUrl = (redirectToEditor: boolean, contentId: string | null) => {
	if (redirectToEditor) {
		return `${CONTEXT_PATH}/pages/resumedraft.action?draftId=${contentId}`;
	}
	return `${CONTEXT_PATH}/pages/viewpage.action?pageId=${contentId}`;
};

/**
 * Before using getUrlForContentType, please consider using `links { webui/editui }` from graphql on Content instead.
 * That value is populated by the BE and helps us have less places to update when adding a new content type in the future.
 * Fetching `Content` isnt always easy. That is when you should use this function
 *    take `Copy` for example. Because it uses the long poll endpoint, we just get the new contentId back. meaning we would have to make a follow up query AFTER creation finishes in order to get the web links
 *    instead of effecting performance and complexity by adding another call, we just use this function to get the redirect url
 *
 * Since this function is used by some flows, please add to it when creating a new content type.
 */
export const getUrlForContentType = ({
	contentType,
	contentSubType = null,
	contentId,
	spaceKey,
	redirectToEditor = false,
	useLegacyActionUrl = false,
}: GetURLForContentTypeProps) => {
	// assume all page sub types will only redirect to view page
	// can refactor to be more specific if needed
	if (contentType === 'page' && contentSubType !== null) {
		return VIEW_PAGE.toUrl({ spaceKey, contentId });
	}

	if (contentType === 'page' && useLegacyActionUrl) {
		return getLegacyActionUrl(redirectToEditor, contentId);
	}

	if (contentType === 'page' && redirectToEditor) {
		return EDIT_PAGE_V2.toUrl({
			spaceKey,
			contentType: 'pages',
			contentId,
		});
	}
	if (contentType === 'blogpost' && redirectToEditor) {
		return EDIT_BLOG_V2.toUrl({
			spaceKey,
			contentId,
			contentType: 'blog',
		});
	}

	switch (contentType) {
		case 'page':
			return VIEW_PAGE.toUrl({ spaceKey, contentId });
		case 'blogpost':
			return VIEW_BLOG.toUrl({ spaceKey, contentId });
		case 'whiteboard':
			return WHITEBOARD_BOARD.toUrl({ spaceKey, contentId });
		case 'database':
			return DATABASE_VIEW.toUrl({ spaceKey, contentId });
		case 'embed':
			return EMBED_VIEW.toUrl({ spaceKey, contentId });
		case 'folder':
			return FOLDER_VIEW.toUrl({ spaceKey, contentId });
	}
	return null;
};
