import React from 'react';

import { token } from '@atlaskit/tokens';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';

import { BreadcrumbsEllipsisButton } from './BreadcrumbsAncestorsDropdownButton';

export const BreadcrumbsAncestorsDropdownButtonPlaceholder = () => {
	return (
		<BreadcrumbsEllipsisButton
			isSelected={false}
			appearance="subtle"
			iconBefore={<ShowMoreHorizontalIcon label="More" color={token('color.icon')} />}
			data-vc="breadcrumbs-ellipsis-button-placeholder"
		/>
	);
};
