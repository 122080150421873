import React from 'react';

import PageIcon from '@atlaskit/icon/core/page';
import WhiteboardIcon from '@atlaskit/icon/core/whiteboard';
import DatabaseIcon from '@atlaskit/icon/core/database';
import FolderOpenIcon from '@atlaskit/icon/core/folder-open';
import FolderClosedIcon from '@atlaskit/icon/core/folder-closed';

import type { ContentTreeTypes } from './contentTreeTypes';

export type ContentTreeIconSpacing = 'none' | 'spacious';
type IconComponent = (props: any) => JSX.Element;

// Remove this component once these icons become available in atlaskit
export const ContentTreeIcon = ({
	label,
	color, // can override default icon color
	spacing,
	type,
	isExpanded = false,
}: {
	label: string;
	color?: string;
	spacing?: ContentTreeIconSpacing;
	type: ContentTreeTypes;
	isExpanded?: boolean;
}) => {
	const contentTreeIconTypes: Record<ContentTreeTypes, IconComponent> = {
		page: PageIcon,
		whiteboard: WhiteboardIcon,
		database: DatabaseIcon,
		folder: isExpanded ? FolderOpenIcon : FolderClosedIcon,
	};

	const icon = contentTreeIconTypes[type];
	const testId = `content-tree-icon${spacing !== 'none' ? `-${spacing}` : ''}-${type}${
		type === 'folder' && isExpanded ? '-open' : ''
	}`;

	return React.createElement(icon, {
		spacing,
		label,
		color,
		testId,
	});
};
