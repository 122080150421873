import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';

import type { PopupProps } from '@atlaskit/popup';
import Popup from '@atlaskit/popup';
import { Flex, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

import { ErrorDisplay } from '@confluence/error-boundary';
import { CONTEXT_PATH } from '@confluence/named-routes';

import { BreadcrumbsFolderChildrenQuery } from './BreadcrumbsFolderChildrenQuery.graphql';
import type {
	BreadcrumbsFolderChildrenQuery as QueryData,
	BreadcrumbsFolderChildrenQueryVariables as QueryVars,
} from './__types__/BreadcrumbsFolderChildrenQuery';
import type { Content } from './BreadcrumbsContentMenu';
import { BreadcrumbsContentMenu } from './BreadcrumbsContentMenu';

type BreadCrumbsFolderPopupProps = {
	contentId: string;
	isOpen: boolean;
	onClose: () => void;
	trigger: PopupProps['trigger'];
	placement: PopupProps['placement'];
	handleClickFolderDropdownItem?: (content: Content, index: number) => void;
};

export const BreadcrumbsFolderPopup = ({
	contentId,
	isOpen,
	onClose,
	trigger,
	placement,
	handleClickFolderDropdownItem,
}: BreadCrumbsFolderPopupProps) => {
	return (
		<Popup
			trigger={trigger}
			content={() => (
				<BreadcrumbsFolderContent
					contentId={contentId}
					handleClickFolderDropdownItem={handleClickFolderDropdownItem}
				/>
			)}
			isOpen={isOpen}
			onClose={onClose}
			placement={placement}
		/>
	);
};

type BreadcrumbsFolderContentProps = {
	contentId: string;
	handleClickFolderDropdownItem?: (content: Content, index: number) => void;
};

const BreadcrumbsFolderContent = ({
	contentId,
	handleClickFolderDropdownItem,
}: BreadcrumbsFolderContentProps) => {
	const { loading, data, error } = useQuery<QueryData, QueryVars>(BreadcrumbsFolderChildrenQuery, {
		variables: { contentId },
	});

	if (loading) {
		return (
			<Flex alignItems="center" justifyContent="center" xcss={containerStyles}>
				<Spinner size="large" />
			</Flex>
		);
	}

	if (error) {
		return (
			<ErrorDisplay error={error}>
				<Flex alignItems="center" justifyContent="center" xcss={containerStyles}>
					<FormattedMessage {...i18n.error} />
				</Flex>
			</ErrorDisplay>
		);
	}

	const contents =
		data?.ptpage?.children?.nodes?.filter(isTruthy).map((c) => ({
			id: c.id,
			type: c.type || '',
			title: c.title || '',
			href: `${CONTEXT_PATH}${c.links?.webui}`,
		})) || [];
	const hasMore = data?.ptpage?.children?.pageInfo?.hasNextPage;

	if (!contents.length) {
		return (
			<Flex alignItems="center" justifyContent="center" xcss={containerStyles}>
				<FormattedMessage {...i18n.empty} />
			</Flex>
		);
	}

	return (
		<BreadcrumbsContentMenu
			contents={contents}
			showIndentIcon={false}
			hasMore={hasMore}
			onLinkItemClick={handleClickFolderDropdownItem}
		/>
	);
};

const containerStyles = xcss({
	width: '280px',
	paddingInline: 'space.150',
	paddingBlock: 'space.500',
	color: 'color.text.subtlest',
});

const isTruthy = <T,>(value: T | null | undefined): value is T => {
	return value !== null && value !== undefined;
};

const i18n = defineMessages({
	error: {
		id: 'breadcrumbs.folder.error',
		defaultMessage: 'Error loading folder contents',
		description: 'Error message when folder contents fail to load in the breadcrumbs menu',
	},
	empty: {
		id: 'breadcrumbs.folder.empty',
		defaultMessage: 'There’s nothing in this folder yet.',
		description: 'Message when folder is empty in the breadcrumbs menu',
	},
});
