import gql from 'graphql-tag';

export const BreadcrumbsFolderChildrenQuery = gql`
	query BreadcrumbsFolderChildrenQuery($contentId: ID!) {
		ptpage(id: $contentId, status: [CURRENT]) {
			id
			children(first: 5) {
				nodes {
					id
					title
					type
					links {
						webui
					}
					emojiTitlePublished: properties(key: "emoji-title-published") {
						nodes {
							id
							key
							value
							version {
								number
							}
						}
					}
				}
				pageInfo {
					hasNextPage
					endCursor
				}
			}
		}
	}
`;
