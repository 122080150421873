export enum GenericBreadcrumbItemTitles {
	Blog = 'BLOG',
	Overview = 'OVERVIEW',
	Pages = 'PAGES',
	People = 'PEOPLE',
	Space = 'SPACE',
	PersonalSpace = 'PERSONAL_SPACE',
	ContentTitle = 'CONTENT_TITLE',
	Ancestor = 'ANCESTOR',
	BlogYear = 'BLOG_YEAR',
	BlogMonth = 'BLOG_MONTH',
	BlogDay = 'BLOG_DAY',
}
