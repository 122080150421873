import type { FC } from 'react';
import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

import type { BreadcrumbsProps } from './Breadcrumbs';
import { BreadcrumbsPlaceholder } from './BreadcrumbsPlaceholder';
import { Breadcrumbs as BreadcrumbsComponent } from './Breadcrumbs';

export const BreadcrumbsLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Breadcrumbs" */ './Breadcrumbs')).Breadcrumbs,
	loading: BreadcrumbsPlaceholder,
});
export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => <BreadcrumbsLoader {...props} />;

export const BreadcrumbsNonLoadable: FC<BreadcrumbsProps> = (props) => (
	<BreadcrumbsComponent {...props} />
);

export const FixedBreadcrumbsNavForPageLayoutLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Breadcrumbs" */ './Breadcrumbs'))
			.FixedBreadcrumbsNavForPageLayout,
});

export { FixedSpaceHeaderContainer, FixedSpaceHeaderWithBannerContainer } from './Breadcrumbs';
