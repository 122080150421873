import { SPACE_ARCHIVED_PAGES, SPACE_PAGES, WHITEBOARD_BOARD } from '@confluence/named-routes';

export const getSkipContentAndTypes = (matchName?: string | null) => {
	const isArchivedPagesList = matchName === SPACE_ARCHIVED_PAGES.name;
	const isSpacePages = matchName === SPACE_PAGES.name;
	const isWhiteboard = matchName === WHITEBOARD_BOARD.name;

	const skipContent = isArchivedPagesList || isSpacePages;
	return { skipContent, isArchivedPagesList, isSpacePages, isWhiteboard };
};
