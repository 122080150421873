import { useCallback } from 'react';

import { getBooleanFF } from '@atlaskit/platform-feature-flags';

import { fg } from '@confluence/feature-gating';
import { useSessionData } from '@confluence/session-data';

import features from './whiteboard-feature-flags.json';
import featureGates from './whiteboard-feature-gates.json';

export type WhiteboardFeatures = keyof typeof features | keyof typeof featureGates;

// These flags are no longer used in confluence and can be removed from this array entirely after we confirm that have been removed from the whiteboards side too
const ARCHIVED_FEATURE_FLAGS = [
	'headerV1',
	'headerV2',
	'whiteboardArchive',
	'removeWhiteboardsHome',
	'removeWhiteboardsHomeInSideNav',
	'whiteboardCreation',
] as const;
type ArchivedFeatureFlags = (typeof ARCHIVED_FEATURE_FLAGS)[number];
type ActiveFeatureFlags = keyof typeof features;
type ActiveFeatureGates = keyof typeof featureGates;

export const useIsWhiteboardFeatureEnabled = () => {
	const { featureFlagClient } = useSessionData();

	const isWhiteboardFeatureEnabled = useCallback(
		(featureName: ActiveFeatureFlags | ArchivedFeatureFlags | ActiveFeatureGates) => {
			// If the FF has been cleaned up on our end just return true
			if (ARCHIVED_FEATURE_FLAGS.some((feature) => feature === featureName)) {
				return true;
			}

			if (featureName in featureGates) {
				const activeFeatureGate = featureName as ActiveFeatureGates;
				switch (activeFeatureGate) {
					case 'whiteboardSolidHeader':
						return fg('confluence_frontend_solid_whiteboard_header');
					case 'whiteboardVersionHistory':
						return fg('confluence_frontend_whiteboards_version_history_ui');
					default:
						throw new Error('unable to find feature gate in whiteboard-feature-gates.json');
				}
			}

			return (
				featureName in features &&
				featureFlagClient.getBooleanValue(features[featureName as ActiveFeatureFlags], {
					default: false,
					shouldTrackExposureEvent: true,
				})
			);
		},
		[featureFlagClient],
	);

	const isWhiteboardCreationDropdownEnabled = useCallback((): boolean => {
		return (
			isWhiteboardFeatureEnabled('whiteboardsEnabled') &&
			!isWhiteboardFeatureEnabled('whiteboardOptOutPhase1')
		);
	}, [isWhiteboardFeatureEnabled]);

	const isWhiteboardGAOnboardingEnabled = useCallback(() => {
		return (
			isWhiteboardFeatureEnabled('gaOnboarding') &&
			isWhiteboardFeatureEnabled('whiteboardsEnabled') &&
			!isWhiteboardFeatureEnabled('whiteboardOptOutPhase1')
		);
	}, [isWhiteboardFeatureEnabled]);

	return {
		platformFeatureFlagResolver: getBooleanFF,
		isWhiteboardFeatureEnabled,
		isWhiteboardCreationDropdownEnabled,
		isWhiteboardGAOnboardingEnabled,
	};
};
