import React from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { ButtonItem, LinkItem, MenuGroup } from '@atlaskit/menu';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { N200 } from '@atlaskit/theme/colors';

import { ContentTreeIcon } from '@confluence/icons/entry-points/ContentTreeIcon';
import { LoadableAfterPaint } from '@confluence/loadable';
import { getUrlForContentType } from '@confluence/content-types-utils';
import { useSpaceKey } from '@confluence/space-utils';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';

const IndentIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-IndentIcon" */ './img/IndentIcon')).IndentIcon,
	loading: () => <IndentIconPlaceholder />,
});

type BreadCrumbsContentMenuProps = {
	contents: Content[];
	showIndentIcon?: boolean;
	onLinkItemClick?: (content: Content, index: number) => void;
	hasMore?: boolean;
};

export type Content = {
	id: string;
	type;
	title: string;
	href: string;
};

export const BreadcrumbsContentMenu = ({
	contents,
	showIndentIcon,
	onLinkItemClick,
	hasMore,
}: BreadCrumbsContentMenuProps) => {
	const spaceKey = useSpaceKey();
	const { push } = useRouteActions();

	return (
		<Box paddingBlock="space.075">
			<MenuGroup maxWidth={280} minWidth={280} onClick={(e) => e.stopPropagation()}>
				{contents.map((content, i) => {
					const isFolder = content.type === 'folder';
					const iconBefore = showIndentIcon ? (
						i > 0 ? (
							<IndentIcon />
						) : (
							<IndentIconPlaceholder />
						)
					) : undefined;

					const onFolderItemClick = () => {
						onLinkItemClick?.(content, i);
						if (spaceKey) {
							const destinationUrl = getUrlForContentType({
								contentType: 'folder',
								contentId: content.id,
								spaceKey,
							});
							destinationUrl && push(destinationUrl);
						}
					};

					if (isFolder) {
						return (
							<FolderItem
								key={i}
								title={content.title}
								iconBefore={iconBefore}
								onClick={onFolderItemClick}
							/>
						);
					}

					return (
						<CustomLinkItem
							key={i}
							iconBefore={iconBefore}
							data-testid="ancestor-breadcrumb-item"
							href={content.href}
							onClick={() => onLinkItemClick?.(content, i)}
						>
							{content.title}
						</CustomLinkItem>
					);
				})}
			</MenuGroup>
			{hasMore && (
				<Box paddingInline="space.150" paddingBlock="space.100" xcss={subtlestText}>
					...
				</Box>
			)}
		</Box>
	);
};

type FolderItemProps = {
	title: string;
	iconBefore: React.ReactNode;
	onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

const FolderItem = ({ title, iconBefore, onClick }: FolderItemProps) => {
	const intl = useIntl();

	return (
		<CustomButtonItem
			iconBefore={iconBefore}
			data-testid="ancestor-breadcrumb-item"
			onClick={onClick}
		>
			<Inline space="space.050" alignBlock="center">
				<Box xcss={folderIconWrapperStyles}>
					<ContentTreeIcon
						type="folder"
						label={intl.formatMessage(i18n.folder)}
						spacing="spacious"
					/>
				</Box>
				{title}
			</Inline>
		</CustomButtonItem>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomLinkItem = styled(LinkItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	padding: '8px 10px !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	minHeight: '32px !important',
	height: '32px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&:hover span': {
		textDecoration: 'underline',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		columnGap: '2px',
		color: token('color.text.subtle', N200),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"span[data-item-elem-before='true']": {
		marginLeft: '-4px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomButtonItem = styled(ButtonItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	padding: '8px 10px !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	minHeight: '32px !important',
	height: '32px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		columnGap: '2px',
		color: token('color.text.subtle', N200),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"span[data-item-elem-before='true']": {
		marginLeft: '-4px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IndentIconPlaceholder = styled.div({
	width: '24px',
	height: '24px',
});

const folderIconWrapperStyles = xcss({
	marginLeft: 'space.negative.050',
});

const subtlestText = xcss({
	color: 'color.text.subtlest',
});

const i18n = defineMessages({
	folder: {
		id: 'breadcrumbs.picker.folder',
		defaultMessage: 'Folder',
		description: 'Label for folder icon in breadcrumbs',
	},
});
